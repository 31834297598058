export default {
    methods: {
        getPaymentMethods() {
            return [
              /*  {
                    text: "Банковской картой",
                    value: "card",
                    icon: () => import("./icons/BankCards"),
                },
                {
                    text: "Безналичный расчет для юр. лиц",
                    value: "bank_invoice",
                    icon: () => import("./icons/invoice.svg?inline"),
                },
                {
                    text: "QIWI",
                    value: "qiwi",
                    icon: () => import("./icons/qiwi.svg?inline"),
                },
                {
                    text: "ЮMoney",
                    value: "yoomoney",
                    icon: () => import("./icons/yoomoney.svg?inline"),
                }, */
                {
                    text: "WeChat",
                    value: "wechat",
                    icon: () => import("./icons/wechat3.svg?inline"),
                },
            ];
        },

        async pay({ order, calculation }) {

            if (
                [/*"card", "qiwi", "yoomoney",*/ "wechat"].includes(order.paymentMethod) &&
                process.env.VUE_APP_PAYMENTS_ROBO_DISABLED
            ) {
                this.$modal.open(
                    () =>
                        import(
                            "@/components/modals/access/ModalPaymentMethodUnavailable"
                        ),
                    {}
                );
                throw new Error("PaymentMethodUnavailable");
            }
            const data = await this.$store.dispatch("billing/createOrder", {
                order,
                calculation,
            });
            await this.handlePaymentResult(data);
        },
        async handlePaymentResult(data) {
            const { gatewayData, gatewayUrl, gatewayMethod, order } = data;
            if (data.converted) {
                return;
            }
            if (gatewayData && gatewayUrl && gatewayMethod) {
                const form = document.createElement("form");
                form.method = data.gatewayMethod;
                form.action = data.gatewayUrl;
                Object.keys(gatewayData).forEach((key) => {
                    const hiddenField = document.createElement("input");
                    hiddenField.type = "hidden";
                    hiddenField.name = key;
                    hiddenField.value = gatewayData[key];

                    form.appendChild(hiddenField);
                });
                document.body.appendChild(form);
                form.submit();
            }
            if (order.payment_method === "bank_invoice") {
                try {
                    await Promise.all(
                        order.items.map((item) => {
                            const goals = {
                                1: "pay_start",
                                2: "pay_business",
                                3: "pay_pro",
                            };
                            const goal = goals[item.plan?.main_position];
                            if (!goal) {
                                return;
                            }
                            if (goal) {
                                return this.$telemetry.reachGoal(goal);
                            }
                        })
                    );
                } catch (e) {
                    console.error(e);
                }

                this.$modal.open(
                    () => import("@/components/Billing/ModalInvoiceCreated"),
                    { order: data.order }
                );
                /* const w = window.open("", "_blank");
                const apiBaseURL = this.$api.defaults.baseURL;
                w.location = `${apiBaseURL}/billing/invoice/${data.order.code}`;
                w.focus(); */
            }
        },
        formatMoney(v) {
            const num = Math.round(Number(v) * 100) / 100;
            return `${num.toLocaleString()} ¥`;
        },
    },
};
