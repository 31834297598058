//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { dayjs, VISIBLE_FORMAT } from "@/utils/dates";
import HistoryTable from "@/components/Billing/HistoryTable.vue";
import BillingPromocodeNotice from "@/components/Billing/PromocodeNotice";
//const { CQ_tarif } = require("@/components/CarrotQuest/CarrotQuest.js");

export default {
  components: {
    HistoryTable,
    BillingPromocodeNotice
  },
  meta: {
    title: "套餐与支付"
  },
  data() {
    return {
      billingState: null,
      currentOrganization: null,
      limits: null
    };
  },
  beforeRouteLeave(to, from, next) {
    // console.log('Plan.129: beforeRouteLeave()')
    // console.log('Plan.129: beforeRouteLeave(): sessionStorage.getItem(promocode.type)=', window.sessionStorage.getItem('promocode.type'))

    if (window.sessionStorage.getItem('promocode.type') == 'china'
    // && !to.path.match(/profile/)
    ) {
      //console.log('Plan.135: beforeRouteLeave(): removeItem(promocode.*)')

      window.sessionStorage.removeItem("promocode:code");
      window.sessionStorage.removeItem("promocode:show");
      window.sessionStorage.removeItem("promocode.type");
      window.sessionStorage.removeItem("promocode.class");
      window.sessionStorage.removeItem("promocode.reason");
    }

    //console.log('Plan.129: beforeRouteLeave(): to=', to)
    //console.log('Plan.129: beforeRouteLeave(): from=', from)

    //console.log('Plan.151: -> CQ_tarif()')
    //CQ_tarif(this.$api, this.user);

    next();
  },
  async mounted() {
    await this.load();
    if (this.$route.params.plan) {
      const isSamePlan = this.currentSubscriptions.some(i => i.plan.id === this.$route.params.plan.id);
      if (isSamePlan) {
        this.onProlong(this.$route.params.period);
      } else {
        this.onChange(this.$route.params.plan.id, this.$route.params.period);
      }
    }
  },
  methods: {
    async load() {
      /* //china : мб это ChinaCode
      const promocodeAllowed =
          !!window.sessionStorage.getItem("promocode:show");
      const promocode =
          promocodeAllowed &&
          window.sessionStorage.getItem("promocode:code");
      console.log('Plan.152: promocode=', promocode)
      */

      //                                 V - определено в Promo.vue
      if (window.sessionStorage.getItem("promocode.type") == 'china') {
        try {
          const chinaCode = window.sessionStorage.getItem("promocode:code");
          const r = await this.$store.dispatch("billing/trySetChinaPlan", {
            chinaCode
          });
          //console.log('Plan.183: r=', r)
          if (r) {
            window.sessionStorage.setItem("promocode.type", 'china');
            window.sessionStorage.setItem("promocode.class", r.class);
            window.sessionStorage.setItem("promocode.reason", r.reason);
            //console.log('Plan.188: setItem()')
          }
        } catch (e) {
          var _e$response;
          if (((_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status) === 404) {
            // это не СhinaCode
            window.sessionStorage.removeItem("promocode.type");
            window.sessionStorage.removeItem("promocode.class");
            window.sessionStorage.removeItem("promocode.reason");
            //console.log('Plan.195: r=404: !chinaCode')
          }
        }
      }

      return Promise.all([this.$store.dispatch("user/getUser", {
        force: true
      }), this.loadBillingState(), this.loadCurrentOrganization(), this.loadLimits()]);
    },
    async loadBillingState() {
      this.billingState = await this.$store.dispatch("billing/getState");
    },
    async loadCurrentOrganization() {
      this.currentOrganization = await this.$store.dispatch("billing/getCurrentOrganization").catch(e => {
        var _e$response2;
        return ((_e$response2 = e.response) === null || _e$response2 === void 0 ? void 0 : _e$response2.status) === 404 ? null : Promise.reject(e);
      });
    },
    async onChangeCurrentOrganization() {
      const organization = await this.$modal.process(() => import("@/components/Billing/ModalCustomerOrganization"), {
        data: this.currentOrganization || {}
      });
      this.currentOrganization = await this.$store.dispatch("billing/updateCurrentOrganization", organization);
    },
    async loadLimits() {
      this.limits = await this.$store.dispatch("billing/getLimits");
    },
    async onChange(preferredPlanId, preferredPeriod) {
      var _this$currentSubscrip;
      if (this.user.parent) {
        return;
      }
      await this.$modal.process(() => import("@/components/Billing/ModalPayment"), {
        type: (_this$currentSubscrip = this.currentSubscriptions) !== null && _this$currentSubscrip !== void 0 && _this$currentSubscrip.length ? "change" : "first",
        preferredPlanId,
        preferredPeriod
      });
      await this.load();
      /*
      // this.$refs.modalPayment.open({
      //     type: "change",
      // });
      */
    },

    async onProlong(preferredPeriod) {
      if (this.user.parent) {
        return;
      }
      await this.$modal.process(() => import("@/components/Billing/ModalPayment"), {
        type: "prolong",
        preferredPeriod
      });
      await this.load();
    }
  },
  computed: {
    backmessage() {
      if ('error' == this.$route.query.back) {
        return '付款时发生错误. 请稍后再试.';
      } else {
        if ('success' == this.$route.query.back) {
          return '付款成功.';
        }
      }
      return '';
    },
    backms() {
      if ('error' == this.$route.query.back) {
        return true;
      }
      return false;
    },
    backms2() {
      if ('success' == this.$route.query.back) {
        return true;
      }
      return false;
    },
    promocodeType() {
      //china
      return window.sessionStorage.getItem("promocode.type");
      ;
    },
    currentSubscriptions() {
      return this.$store.state.billing.currentSubscriptions;
    },
    planName() {
      var _this$currentSubscrip2, _this$currentSubscrip3;
      if (((_this$currentSubscrip2 = this.currentSubscriptions) === null || _this$currentSubscrip2 === void 0 || (_this$currentSubscrip2 = _this$currentSubscrip2[0]) === null || _this$currentSubscrip2 === void 0 || (_this$currentSubscrip2 = _this$currentSubscrip2.plan) === null || _this$currentSubscrip2 === void 0 ? void 0 : _this$currentSubscrip2.name) === "Demo") {
        return "Demo (24小时免费检测)";
      }
      return ((_this$currentSubscrip3 = this.currentSubscriptions) === null || _this$currentSubscrip3 === void 0 || (_this$currentSubscrip3 = _this$currentSubscrip3[0]) === null || _this$currentSubscrip3 === void 0 || (_this$currentSubscrip3 = _this$currentSubscrip3.plan) === null || _this$currentSubscrip3 === void 0 ? void 0 : _this$currentSubscrip3.name) || "Free";
    },
    /*
    planId() {
        return this.currentSubscriptions?.[0]?.plan?.id;
    },
    */
    minExpirationDate() {
      const getMinExpiration = subscriptions => subscriptions.reduce((min, subscription) => {
        const date = dayjs(subscription.date_end);
        if (!min) {
          return date;
        }
        return date.isBefore(min) ? date : min;
      }, null);
      return getMinExpiration(this.billingState.currentSubscriptions);
    },
    expiration() {
      const minDate = this.minExpirationDate;
      return minDate ? minDate.format(VISIBLE_FORMAT) : "-";
    },
    isExpired() {
      if (!this.minExpirationDate) {
        return false;
      }
      return this.minExpirationDate.isBefore(dayjs());
    },
    user() {
      return this.$store.state.user.user;
    },
    ready() {
      return this.user && this.billingState && this.currentSubscriptions && this.limits;
    }
  }
};